import { FunctionComponent } from 'react';
import styles from './SubHeader.module.scss';

const SubHeader: FunctionComponent = () => {
  const GoBackToUtils = () => {
    window.location.replace('https://utils.umojodev.com/');
  };
  return (
    <div className={styles.subHeaderBox}>
      <div className={styles.navigationTitles}>Accounts</div>
      <div>
        <button className={styles.actionButtonGray} onClick={GoBackToUtils}>
          Back to Umojo Utils
        </button>
      </div>
    </div>
  );
};

export default SubHeader;
